import { template as template_ae41b51e94844f1e8dfb5b98e9d8e0a6 } from "@ember/template-compiler";
const WelcomeHeader = template_ae41b51e94844f1e8dfb5b98e9d8e0a6(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
