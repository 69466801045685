import { template as template_aa81f58df0cb48d5af14b5c332d3d792 } from "@ember/template-compiler";
const FKControlMenuContainer = template_aa81f58df0cb48d5af14b5c332d3d792(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
