import { template as template_79ea42858f0249a59b30d7f280886d2a } from "@ember/template-compiler";
const FKLabel = template_79ea42858f0249a59b30d7f280886d2a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
